import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
    FEATURE_FLAG,
    FeatureFlagService,
    UserApiService,
} from '@quipex/shared/data';
import { filter, map } from 'rxjs';

export const accessGuard = () => {
    const userService = inject(UserApiService);
    const router = inject(Router);
    const featureFlagService = inject(FeatureFlagService);

    return userService.userMe$.pipe(
        filter((currentUser) => currentUser !== null),
        map((currentUser) => {
            if (currentUser?.role) {
                const accessPermission = currentUser.permissions?.find(
                    (permission) => permission.toLowerCase() === 'read_access'
                );

                const isContractorManagementFeatureEnabled =
                    featureFlagService.isFeatureEnabled(
                        FEATURE_FLAG.ContractorManagement
                    );

                if (
                    !accessPermission ||
                    !isContractorManagementFeatureEnabled
                ) {
                    router.navigateByUrl('/');
                    return false;
                }
                return true;
            }
            return false;
        })
    );
};
