import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'modules/shared/data/src/lib/services/auth.service';
import { environment } from '../../environments/environment';

export const insightsGuard = () => {
    const router = inject(Router);
    const authService = inject(AuthService);

    // Completely disable insights in production
    if (environment.isProd) {
        router.navigateByUrl('/');
        return false;
    }

    // In demo environment, only allow specific users
    if (environment.isDemo) {
        // Get the current user account
        const account = authService.getAccount();
        const authorizedEmails = environment.authorizedUsers?.insights || [];

        if (account && authorizedEmails.includes(account.username)) {
            return true;
        } else {
            router.navigateByUrl('/');
            return false;
        }
    }

    // In all other environments (dev, qa, etc.), allow all users
    return true;
};
